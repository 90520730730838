html,
body,
#root {
  margin: 0;
  height: 100%;
}


/* Order table fix */
.ant-table-row-expand-icon.ant-table-row-expand-icon-spaced {
  display: none;
}

/* Form item fix */
.ant-form-item {
  margin-bottom: 8px !important;
}